
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { Field } from 'vee-validate';
import { VueCookieNext } from 'vue-cookie-next';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';

export default defineComponent({
  name: 'kt-drawer-course-termupdate',
  components: { Field },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      load: false,
      loading: false,
      bills: [] as any,
      tempbills: [] as any,
      courseInfo: {} as any,
      percentageInfo: '',
      unit_cost_total: '',
      id: this.$route.params.id,
      // bill_data: [] as any,
      // temp_data: [] as any,
      // bill_data_final: [] as any,
    };
  },
  async created() {
    this.emitter.on('course_bill_update', async (id) => {
      this.load = true;

      await this.getCourseDetails(id);
      await this.courseBillLog(id);
    });
    // this.emitter.on("course-approve-update", async () => {
    //   await this.getCourseDetails(this.id);
    // });
  },
  methods: {
    async getCourseDetails(id) {
      this.load = true;

      await ApiService.get('course/show/' + `${id}`)
        .then((response) => {
          this.load = true;
          this.courseInfo = response.data.data;
          this.bills = [] as any;
          this.bills = response.data.data.course_bill_info;
          console.log(this.bills);
          //console.log(3 - this.bills.length);
          if (3 - this.bills.length) {
            for (let i = 0; i < 3 - this.bills.length; i++) {
              let temp = {
                id: '',
                x_claim_milestone_id: `CLAIM-${this.bills.length + (i + 1)}`,
                entity_course_id: this.courseInfo.id,
                x_training_milestone_id: '',
                // milestone_param: "",
                claim_percentage: '',
                adj_adv_reduction_perc: '',
              };
              this.tempbills.push(temp);
              // console.log(i);
            }
            // console.log(this.tempbills);
            if (this.bills.length > 0) {
              this.bills.push(this.tempbills);
            } else {
              this.bills = this.tempbills;
            }

            this.tempbills = [] as any;
            // console.log("after push" + this.bills);
          }

          this.percentageInfo = response.data.data.entity_tranche_contract_id;
          this.unit_cost_total = response.data.data.unit_cost_total;
          this.getContractPercentage(this.percentageInfo);

          this.load = false;
          // console.log();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getContractPercentage(id) {
      await ApiService.get('entity/contract/show/' + id)
        .then((response) => {
          this.percentageInfo = response.data.data?.contribution_percentage;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async courseBillLog(id) {
      this.load = true;

      await ApiService.get('course/courseBillEdit/' + id)
        .then((response) => {
          this.load = false;
          if (response.data.data && response.data.data != '') {
            // this.load = false;
            this.load = false;
            this.bills = [];
            for (let i = 0; i < 3; i++) {
              if (response.data.data[i] && response.data.data != '') {
                this.bills.push(response.data.data[i]);
              }

              // else {
              //   let temp = {
              //     id: "",
              //     x_claim_milestone_id: `CLAIM-${this.bills.length + 1}`,
              //     entity_course_id: this.courseInfo.id,
              //     x_training_milestone_id: "",
              //     milestone_param: "",
              //     claim_percentage: "",
              //     adj_adv_reduction_perc: "",
              //   };
              //   this.tempbills.push(temp);
              // }
            }
            // this.bills.push(this.tempbills);

            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();
      formData.set('bill_info', JSON.stringify(this.bills));
      formData.set('course_id', this.courseInfo.id);
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      this.loading = true;
      await ApiService.post('course/billupdate', formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.data.status == 'success') {
            this.loading = false;
            DrawerComponent?.hideAll();

            Swal.fire({
              title: 'Success!',
              text: 'Bill Updated Successfully',
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.emitter.emit('course-approve-update', true);
            });
          }

          if (response.data.status == 'error') {
            this.loading = false;
            DrawerComponent?.hideAll();

            Swal.fire({
              title: 'Error!',
              text: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            }).then(() => {});
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const CourseInfoSchema = Yup.object().shape({});
    return {
      CourseInfoSchema,
    };
  },
});
