
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';

export default defineComponent({
  name: 'kt-drawer-course-unique-details',
  components: { Field },

  data() {
    return {
      load: false,
      loading: false,
      unique_course_details: [] as any,

      // bill_data: [] as any,
      // temp_data: [] as any,
      // bill_data_final: [] as any,
    };
  },
  async created() {
    this.emitter.on('course_unique_details', async (data) => {
      this.unique_course_details = data;
    });
    // this.emitter.on("course-approve-update", async () => {
    //   await this.getCourseDetails(this.id);
    // });
  },

  setup() {
    const CourseUniqueDetailsSchema = Yup.object().shape({});
    return {
      CourseUniqueDetailsSchema,
    };
  },
});
