
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import * as Yup from 'yup';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';

export default defineComponent({
  name: 'kt-drawer-course-configupdate',
  components: { Field },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      percentageInfo: '',
      courseTermCount: 0,
      tranche: [],
      association: [],
      update_status: 0,
      assesmentchecked: 'no',
      certificationchecked: 'no',

      load: false,
      loading: false,
      formData: {
        id: '',
        entity_course_id: '',
        e_grace: '',

        dropout_rep_perc_per_batch: '',
        assessment_applicable: 0,
        certification_applicable: 0,
        emp_bill_claim_perc: '',
      },

      // bill_data: [] as any,
      // temp_data: [] as any,
      // bill_data_final: [] as any,
    };
  },
  async created() {
    this.emitter.on('course_config_update', async (data) => {
      this.loading = false;
      this.load = true;
      await this.getCourseDetails(data);
      await this.courseConfigLog(data);
    });
  },
  methods: {
    async getCourseDetails(id) {
      this.load = true;
      await ApiService.get('course/show/' + `${id}`)
        .then((response) => {
          this.load = true;
          (this.assesmentchecked = 'no'), (this.certificationchecked = 'no');

          if (
            response.data.data.course_config_info?.assessment_applicable == 1
          ) {
            this.assesmentchecked = 'yes';
          }
          if (
            response.data.data.course_config_info?.certification_applicable == 1
          ) {
            this.certificationchecked = 'yes';
          }
          this.update_status = response.data.data.update_status;
          this.formData = response.data.data.course_config_info;
          this.load = false;
          // console.log();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async courseConfigLog(id) {
      this.load = true;
      await ApiService.get('course/courseConfigEdit/' + id)
        .then((response) => {
          this.load = false;

          if (this.update_status == 1 && response.data.data != '') {
            this.load = true;
            (this.assesmentchecked = 'no'), (this.certificationchecked = 'no');

            // this.load = false;
            // console.log(response.data.data);
            // this.load = false;
            if (
              response.data.data?.assessment_applicable == 1 ||
              response.data.data?.assessment_applicable == '1'
            ) {
              this.assesmentchecked = 'yes';
            }
            if (
              response.data.data?.certification_applicable == 1 ||
              response.data.data?.certification_applicable == '1'
            ) {
              this.certificationchecked = 'yes';
            }

            this.formData = response.data.data;
            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    isAssesmentCheck(event) {
      if (event.target.checked) {
        this.formData.assessment_applicable = 1;
      } else {
        this.formData.assessment_applicable = 0;
      }
    },
    isCertificationCheck(event) {
      if (event.target.checked) {
        this.formData.certification_applicable = 1;
      } else {
        this.formData.certification_applicable = 0;
      }
    },

    async getContractPercentage(id) {
      await ApiService.get('entity/contract/show/' + id)
        .then((response) => {
          this.percentageInfo = response.data.data?.contribution_percentage;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async formSubmit() {
      let formData = new FormData();
      for (var key in this.formData) {
        formData.set(key, this.formData[key]);
      }
      formData.set('course_id', this.formData.id);
      this.loading = true;
      await ApiService.post('course/configupdate', formData)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.loading = false;
            DrawerComponent?.hideAll();
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              this.emitter.emit('course-approve-update', true);
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Submission Error',
              html: response.data.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const CourseInfoSchema = Yup.object().shape({});
    return {
      CourseInfoSchema,
    };
  },
});
