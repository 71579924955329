
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { Field } from "vee-validate";
import { VueCookieNext } from 'vue-cookie-next';
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-course-termupdate",
  components: { Field },
  props: {
    data: { type: Object },
  },
  data() {
    return {
      update_status: 0,
      percentageInfo: "",
      courseTermCount: 0,
      tranche: [],
      association: [],
      id: this.$route.params.id,
      load: false,
      loading: false,
      terms: [] as any,
      temp_data: [] as any,
      courseInfo: {} as any,
      term: "" as any,
      month: "" as any,
      hour: "" as any,
      session: "" as any,
      day: "" as any,

      // bill_data: [] as any,
      // temp_data: [] as any,
      // bill_data_final: [] as any,
    };
  },
  async created() {
    this.emitter.on("course_term_update", async (id) => {
      this.load = true;
      this.getCourseDetails(id);
      // if (this.update_status == 1) {
      //   await this.courseLog(id);
      // }

      await this.courseTermLog(id);
    });
    // this.emitter.on("course-approve-update", async () => {
    //   await this.getCourseDetails(this.id);
    // });
  },
  methods: {
    async courseLog(id) {
      this.load = true;
      await ApiService.get("course/edit/" + id)
        .then((response) => {
          this.load = false;
          if (this.update_status == 1) {
            this.load = true;

            if (response.data.data) {
              console.log(response.data.data);
              this.term = response.data.data.term;
              this.month = response.data.data.month;
              this.hour = response.data.data.hour;
              this.session = response.data.data.session;
              this.day = response.data.data.day;
            }
            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    courseTermGenerate() {
      console.log("terms" + this.terms.length);
      console.log("term_no" + this.term);

      // get last index
      let lastcourseTermIndex = this.terms.length;
      if (lastcourseTermIndex < +this.term) {
        for (let i = lastcourseTermIndex; i < +this.term; i++) {
          let term_data_temp = {
            id: "",
            course_info_id: this.courseInfo.id,
            term_no: lastcourseTermIndex + 1,
            term_month: "",
            term_day: "",
            term_session: "",
            term_hour: "",
            class_type: "",
            week_session: "",
          };

          this.terms.push(term_data_temp);
          this.temp_data.push(term_data_temp);
        }
      } else if (+this.term == +lastcourseTermIndex) {
        this.terms = [] as any;
        this.terms = this.temp_data;
        // console.log("2nd");
        // console.log("temp data length" + this.temp_data.length);
      } else {
        let lastIndex = this.terms.length;
        let spliceNumber = +lastIndex - +this.term;
        this.terms = this.terms.slice(0, -spliceNumber);
        // console.log("3rd");
        // console.log("temp data length" + this.temp_data.length);
      }
    },

    async getCourseDetails(id) {
      await ApiService.get("course/show/" + `${id}`)
        .then((response) => {
          this.load = true;
          this.courseInfo = response.data.data;
          this.term = response.data.data.term;
          this.month = response.data.data.month;
          this.day = response.data.data.day;
          this.hour = response.data.data.hour;
          this.session = response.data.data.session;
          this.terms = response.data.data.course_term_info;
          this.update_status = response.data.data.update_status;

          this.load = false;
          // console.log();
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async courseTermLog(id) {
      this.load = true;
      await ApiService.get("course/courseTermEdit/" + id)
        .then((response) => {
          this.load = false;
          if (response.data.data) {
            // this.load = false;
            // console.log(response.data.data);
            // this.load = false;
            this.terms = [];
            this.term = response.data.data.length;

            this.terms = response.data.data;
            this.load = false;
          }
        })
        .catch(({ response }) => {
          this.load = false;
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();
      formData.set("all_term", JSON.stringify(this.terms));
      formData.set("course_id", this.courseInfo.id);
      formData.set("month", this.month);
      formData.set("day", this.day);
      formData.set("hour", this.hour);
      formData.set("session", this.session);
      formData.set("term", this.term);
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set('user_id', user_id.id);
      this.loading = true;
      await ApiService.post("course/termupdate", formData)
        .then((response) => {
          console.log(response);
          this.loading = false;
          if (response.data.status == "success") {
            this.loading = false;
            DrawerComponent?.hideAll();

            Swal.fire({
              title: "Success!",
              text: "Term Updated Successfully",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.emitter.emit("course-approve-update", true);
            });
          }

          if (response.data.status == "error") {
            this.loading = false;
            DrawerComponent?.hideAll();

            Swal.fire({
              title: "Error!",
              text: response.data.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            }).then(() => {});
          }
        })
        .catch(({ response }) => {
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
  setup() {
    const CourseInfoSchema = Yup.object().shape({});
    return {
      CourseInfoSchema,
    };
  },
});
