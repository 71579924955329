
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
import { VueCookieNext } from "vue-cookie-next";
import { apiEndpoint } from "@/mixin/apiMixin.js";

export default defineComponent({
  name: "kt-drawer-addcourseChapter",
  components: { Field },
  mixins: [apiEndpoint],
  data() {
    return {
      load: false,
      id: this.$route.params.id as any,
      course_chapter: [
        {
          id: "",
          chapter_name: "",
        },
      ] as any,
      chapter: {
        id: "",
        chapter_name: "",
      } as any,
      loading: false,
      course_id: "" as any,
    };
  },
  async created() {
    this.emitter.on("chapter-add", async (course_id) => {
      this.course_id = course_id;
      await this.getCourseChapterList();
    });
  },
  methods: {
    addChapter() {
      this.course_chapter.push(JSON.parse(JSON.stringify(this.chapter)));
    },
    removeChapter() {
      this.course_chapter.length--;
    },

    async getCourseChapterList() {
      this.load = true;
      await ApiService.get(
        this.VUE_APP_COURSE_CHAPTER_List_API + "?course_id=" + this.course_id
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            this.course_chapter = response.data.data;
            this.load = false;
          } else {
            this.course_chapter = [
              {
                id: "",
                chapter_name: "",
              },
            ];
            this.load = false;
          }

          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let formData = new FormData();
      formData.set("chapter_data", JSON.stringify(this.course_chapter));
      formData.set("course_id", this.id);
      let user_id = VueCookieNext.getCookie('_seip_user');
      formData.set("user_id", user_id.id);

      this.loading = true;
      await ApiService.post(this.VUE_APP_COURSE_CHAPTER_SAVE_API, formData)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.data.status == "success") {
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.emitter.emit("chapter-added", true);
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
